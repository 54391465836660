import './App.css';
import IconFacebook from './assets/icons/facebook.svg'
import IconGithub from './assets/icons/github.svg'
import IconInsta from './assets/icons/insta.svg'

function App() {
    return (
        <div className="card">
            <div className="header">
                <div className="social">
                    <a href="https://www.facebook.com/nurrentacarmostar" title="Facebook" target="_blank" rel="noopener noreferrer">
                        <img src={IconFacebook} alt="Facebook Icon" className="icon" />
                    </a>
                    <a href="https://github.com/zuma990" title="GitHub" target="_blank" rel="noopener noreferrer">
                        <img src={IconGithub} alt="Facebook Icon" className="icon" />
                    </a>
                    <a href="https://www.instagram.com/nur_rentacar/?hl=en" title="Instagram" target="_blank" rel="noopener noreferrer">
                        <img src={IconInsta} alt="Facebook Icon" className="icon" />
                    </a>
                </div>
            </div>
            <div className="content">
                <div className="title-holder">
                    <h1>Get ready for the change.</h1>
                    <p>Call us or send a text message via Viber or WhatsApp at +38763318170</p>
                    <p>Website coming soon. Please check back to know more. Shoot us an email if you're curious.</p>
                </div>
                <a href="mailto:info@nur-rentacar.ba">
                    <div className="cta">Send us an email</div>
                </a>
            </div>
            <div className="footer">
                <span>made by <a className="underlined" href="https://github.com/zuma990" target="_blank" rel="noopener noreferrer">Zuma</a> using <a className="underlined" href="https://reactjs.org/" title="ReactJS" target="_blank" rel="noopener noreferrer">React</a> | <a className="underlined" href="https://github.com/zuma990" title="GitHub repo" target="_blank" rel="noopener noreferrer">GitHub</a></span>
            </div>
        </div>
    );
}

export default App;
